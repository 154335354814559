import { formatPhoneNumber } from '~/lib/utils'

/** A stateless, more efficient, more reusable solution for formatting phone number `inputs` */
function actPhoneFormatted() {
	let input: HTMLInputElement | null = null

	return function inputRefCallback(reactRef: HTMLInputElement | null): void {
		if (!reactRef) {
			input?.removeEventListener('input', handleUpdate as EventListener)
			input = null
			return
		}

		input = reactRef
		assertTelephoneInput(input)

		if (input.value) input.value = formatPhoneNumber(input.value)
		input.setAttribute('maxlength', String(12))
		input.setAttribute('pattern', '\\d{3}-\\d{3}-\\d{4}')
		input.addEventListener('input', handleUpdate as EventListener)
	}
}

export default actPhoneFormatted

/* -------------------- Helpers -------------------- */
type InputEditEvent = InputEvent | ClipboardEvent
function handleUpdate(event: InputEditEvent & { target: HTMLInputElement }) {
	// eslint-disable-next-line no-param-reassign -- Normal when it comes to DOM interactions
	event.target.value = formatPhoneNumber(event.target.value)
}

/* -------------------- Assertions -------------------- */
const BadA11yError = new Error("Expected formatted phone number `input` to have `type='tel'`")
BadA11yError.name = 'BadA11yError'

function assertTelephoneInput(input: HTMLInputElement): asserts input is typeof input & { type: 'tel' } {
	if (input.type === 'tel') return
	throw BadA11yError
}
