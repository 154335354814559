import { formatPostalcode, postalCodeRegexPattern } from '~/lib/i18n/postal'

/** A stateless, more efficient, more reusable solution for formatting zip code `inputs` */
export default function actPostalCodeFormatted(countryCode: string) {
	let input: HTMLInputElement | null = null

	return function inputRefCallback(reactRef: HTMLInputElement | null): void {
		if (!reactRef) {
			input?.removeEventListener('input', handleUpdate(countryCode) as EventListener)
			input = null
			return
		}

		input = reactRef

		if (input.value) input.value = formatPostalcode(input.value, countryCode)
		input.setAttribute('maxlength', String(10))
		// We must remove the forward slashes from the regex pattern in order to set it as the input's pattern attribute
		input.setAttribute(
			'pattern',
			postalCodeRegexPattern[countryCode.toUpperCase()]?.pattern.toString().replaceAll('/', ''),
		)
		input.addEventListener('input', handleUpdate(countryCode) as EventListener)
	}
}

/* -------------------- Helpers -------------------- */
type InputEditEvent = InputEvent | ClipboardEvent
function handleUpdate(countryCode: string) {
	return function handleUpdateCallback(event: InputEditEvent & { target: HTMLInputElement }) {
		// eslint-disable-next-line no-param-reassign -- Normal when it comes to DOM interactions
		event.target.value = formatPostalcode(event.target.value, countryCode)
	}
}
