/**
 * Polyfills an `HTMLFormElement` (for browsers lacking proper
 * [`:has`](https://developer.mozilla.org/en-US/docs/Web/CSS/:has) support) so that it properly displays (and styles)
 * the error messages of the fields that it contains.
 *
 * For additional context, see [`~/styles/forms.scss`](./../../styles/forms.scss)
 *
 * **TODO**: Once `:has` has sufficient browser support, this React Action should be deleted in its entirety,
 * as it will then become obsolete.
 */
function polyfillFormErrors() {
	if (typeof window === 'undefined') return undefined
	if (CSS.supports('selector(:has(a))')) return undefined

	/* ---------------------------------------- Setup ---------------------------------------- */
	/** Keeps track of whether or not the underlying `HTMLFormElement` is being unmounted by React */
	let unmounting = false

	/**
	 * `MutationObserver` to synchronize a field's
	 * [`aria-invalid`](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-invalid) attribute
	 * with its `.form-field` container's
	 * `data-invalid` [attribute](https://developer.mozilla.org/en-US/docs/Learn/HTML/Howto/Use_data_attributes).
	 */
	const ariaInvalidObserver = new MutationObserver(ariaInvalidObserverCallback)

	/** The `MutationObserver` callback to use for the {@link ariaInvalidObserver}. */
	function ariaInvalidObserverCallback(mutationList: MutationRecord[]): void {
		mutationList.forEach((mutation) => {
			const field = mutation.target as HTMLInputElement | HTMLSelectElement | HTMLButtonElement | HTMLTextAreaElement
			const formFieldContainer = field.closest('.form-field') as HTMLElement
			formFieldContainer?.setAttribute('data-invalid', field.getAttribute('aria-invalid') as string)
		})
	}

	/**
	 * Watches the underlying `HTMLFormElement` for the addition (or removal) of fields and observes
	 * (or unobserves) them accordingly.
	 */
	const formObserver = new MutationObserver((mutationList) => {
		mutationList.forEach((mutation) => {
			resolveMutationsFor(ariaInvalidObserver, ariaInvalidObserverCallback)
			if (!unmounting) observeFieldsIn(mutation.target as HTMLFormElement)
		})
	})

	/**
	 * Resolves any lingering mutations for the `observer` before `disconnect`ing it. Primarily intended
	 * for use with the {@link ariaInvalidObserver}.
	 */
	function resolveMutationsFor(observer: MutationObserver, callback: (mutationList: MutationRecord[]) => void): void {
		const unresolvedMutations = observer.takeRecords()
		observer.disconnect()
		if (unresolvedMutations.length) callback(unresolvedMutations)
	}

	/** Connects the {@link ariaInvalidObserver} to all of the fields belonging to the provided `form`. */
	function observeFieldsIn(form: HTMLFormElement): void {
		Array.from(form.elements).forEach((e) => {
			ariaInvalidObserver.observe(e, { attributes: true, attributeFilter: ['aria-invalid'] })
		})
	}

	/* ---------------------------------------- Ref Callback Logic ---------------------------------------- */
	return function formRefCallback(reactRef: HTMLFormElement | null): void {
		unmounting = !reactRef

		if (!reactRef) {
			formObserver.disconnect()
			resolveMutationsFor(ariaInvalidObserver, ariaInvalidObserverCallback)
			return
		}

		observeFieldsIn(reactRef)
		formObserver.observe(reactRef, { childList: true })
	}
}

export default polyfillFormErrors
