import React, {
	forwardRef,
	type FunctionComponent,
	memo,
	type ReactNode,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import DialogBase, { type DialogProps } from './DialogBase'
import clsx from 'clsx'
import styles from '~/components/primitives/Dialog/Dialog.module.scss'
import CloseIcon from '~/components/primitives/icons/CloseIcon'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { exposeRefTo } from '~/components/actions'
import type { PolymorphicComponentProps } from '~/types/global'
import type { IconProps } from '~/lib/icons'
import ArrowIcon from '~/components/primitives/icons/ArrowIcon'

/* -------------------- Modular Components -------------------- */
type DialogSlotBaseProps = {
	children: NonNullable<React.ReactNode>
	/** Only Icons types with IconProps are able to be used here, which help enforce the sizing and color
	 * props exposed via that interface. */
	Icon?: FunctionComponent<IconProps>
}
interface DialogTitleSlotBaseProps extends DialogSlotBaseProps {
	onPrevious?: () => void
}
export type DialogSlotProps<E extends React.ElementType> = PolymorphicComponentProps<E, DialogSlotBaseProps>
export type DialogTitleSlotProps<E extends React.ElementType> = PolymorphicComponentProps<E, DialogTitleSlotBaseProps>

const IconElement = ({ Icon }: { Icon?: FunctionComponent<IconProps> }) => {
	return Icon ? <Icon className={styles['dialog-title--icon']} data-testid="dialog-title-icon" size="XXL" /> : <></>
}

/**
 * Slot container for the title element of the dialog component
 */
function Title<E extends React.ElementType>(
	{ as = 'h3' as E, children, Icon, onPrevious, ...attrs }: DialogTitleSlotProps<E>,
	ref: React.ForwardedRef<JSX.Element>,
): JSX.Element {
	const Element = as as React.ElementType
	const formatMessage = useFormatMessage()
	return (
		<Element
			data-dialog-title
			className={clsx(Icon && styles['dialog-title--with-icon'], onPrevious && styles['dialog-title--with-navigation'])}
			ref={ref}
			{...attrs}
		>
			{onPrevious && (
				<ArrowIcon
					aria-label={formatMessage('back')}
					size="MD"
					onClick={onPrevious}
					className={styles['dialog-title--prev-action']}
				/>
			)}
			<IconElement Icon={Icon} />
			<span>{children}</span>
		</Element>
	)
}

function Content<E extends React.ElementType>(
	{ as = 'div' as E, className, ...attrs }: DialogSlotProps<E>,
	ref: React.ForwardedRef<HTMLElement>,
) {
	const Element = as as React.ElementType
	return (
		<Element
			data-dialog-content
			data-testid="dialog-content"
			className={clsx(className, styles['content-wrapper-content'])}
			ref={ref}
			{...attrs}
		/>
	)
}

function Actions<E extends React.ElementType>(
	{ as = 'div' as E, className, ...attrs }: DialogSlotProps<E>,
	ref: React.ForwardedRef<HTMLElement>,
) {
	const Element = as as React.ElementType
	return (
		<Element
			data-dialog-actions
			data-testid="dialog-actions"
			className={clsx(className, styles['content-wrapper-actions'])}
			ref={ref}
			{...attrs}
		/>
	)
}

export interface DialogLayoutProps extends DialogProps {
	children?: [React.ReactElement, React.ReactElement, React.ReactElement] | ReactNode
	/** if false, it will not show the close button icon in the upper-right */
	showCloseIcon?: boolean
	/** This option handles if the modal should close when the user clicks outside the dialog content. */
	closeOnOutsideClick?: boolean
	/** In some instances, the designs call for no padding around the dialog content, this enforces that preference. */
	borderless?: boolean
	/** Option to enforce a full screen rendering of the dialog content */
	fullscreen?: boolean
	onClose?: (e: React.MouseEvent<HTMLDialogElement>) => void
}

type DialogComponentType = DialogLayoutProps

interface DialogSlotContent {
	title?: ReactNode
	content?: ReactNode[]
	actions?: ReactNode
}

/**
 * Wrapper around the native `HTMLDialogElement` that polyfills its features for older browsers.
 * This should be `deprecated` after our minimum supported browser versions support the native
 * `dialog` element.
 */
const Dialog = memo(
	forwardRef<HTMLDialogElement, DialogComponentType>(function Dialog(
		{
			children,
			className = '',
			showCloseIcon = true,
			borderless = false,
			closeOnOutsideClick = true,
			fullscreen = false,
			disableScrollLock = false,
			onClose,
			...attrs
		},
		ref,
	) {
		const dialog = useRef({} as HTMLDialogElement)
		const [dialogContent, setDialogContent] = useState<DialogSlotContent>({})
		const [hasChildSlots, setHasChildSlots] = useState(false)

		const handleCloseButton = (e): void => {
			dialog.current.close()
			if (onClose) onClose(e)
		}

		const getSlotType = (child) => {
			return child?.type?.displayName
		}

		const getDialogContent = useCallback(() => {
			const dialogContentObj: DialogSlotContent = {}
			const contentItem: ReactNode[] = []

			if (Array.isArray(children)) {
				React.Children.forEach(children, (child) => {
					const slotName = getSlotType(child)
					if (slotName === 'Dialog.Title') {
						dialogContentObj.title = child
					} else if (slotName === 'Dialog.Actions') {
						dialogContentObj.actions = child
					} else {
						contentItem.push(child)
					}
				})
			} else {
				contentItem.push(children)
			}
			setHasChildSlots(true) // TODO: Needs to resolve this value appropriately.
			return {
				...dialogContentObj,
				content: contentItem,
			}
		}, [children])

		const handleBaseClose = (e: React.MouseEvent<HTMLDialogElement>) => {
			e.stopPropagation()
			if (onClose) onClose(e)
		}

		useEffect(() => {
			setDialogContent(getDialogContent)
		}, [getDialogContent, children])

		/** TODO: get a better read on the usage of Slots vs legacy Dialog usage. Currently we are not resolving
		 *   the Slots nested in a Fragment on some Dialogs, IE: UserActionModal Login
		 *   we need to conditionally show this class if there are no slots: `content-wrapper--default`
		 */
		return (
			<DialogBase
				className={clsx(
					styles['dialog--ua-dialog'],
					{
						[styles['dialog--ua-dialog-noborder']]: borderless,
						[styles['dialog--ua-dialog-fullscreen']]: fullscreen,
					},
					className,
				)}
				ref={exposeRefTo(dialog, ref)}
				closeOnOutsideClick={closeOnOutsideClick}
				disableScrollLock={disableScrollLock}
				onClose={handleBaseClose}
				{...attrs}
			>
				<div className={styles['dialog--ua-dialog--content']}>
					{showCloseIcon && <DialogCloseButton onCloseClick={handleCloseButton} />}
					<div className={clsx(styles['content-wrapper'], { [styles['content-wrapper--default']]: !hasChildSlots })}>
						{dialogContent?.title}
						{dialogContent?.content}
						{dialogContent?.actions}
					</div>
				</div>
			</DialogBase>
		)
	}),
)

const DialogWithSlots = Object.assign(Dialog, {
	Title: memo(forwardRef(Title)) as typeof Title & { displayName: string },
	Content: memo(forwardRef(Content)) as typeof Content & { displayName: string },
	Actions: memo(forwardRef(Actions)) as typeof Actions & { displayName: string },
})
DialogWithSlots.displayName = 'Dialog'
DialogWithSlots.Title.displayName = 'Dialog.Title'
DialogWithSlots.Content.displayName = 'Dialog.Content'
DialogWithSlots.Actions.displayName = 'Dialog.Actions'

export default DialogWithSlots

/**
 * Dialog-based close button component
 * @param onCloseClick
 */
function DialogCloseButton({ onCloseClick }) {
	const formatMessage = useFormatMessage()
	return (
		<button
			type="button"
			className={styles['close-button']}
			data-testid="dialog-close-button"
			aria-label={formatMessage('close-dialog')}
			onClick={onCloseClick}
		>
			<CloseIcon size="SM" />
			<span className="visually-hidden">{formatMessage('close-dialog')}</span>
		</button>
	)
}
