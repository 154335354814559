import { forwardRef, memo, useEffect, useRef, useState, type MutableRefObject } from 'react'
import { exposeRefTo } from '~/components/actions'
import clsx from 'clsx'
import styles from '~/components/primitives/Dialog/Dialog.module.scss'
import { useDeviceDetect, type CurrentDevice } from '~/components/hooks/useDeviceDetect'

export interface DialogProps extends Omit<React.ComponentPropsWithRef<'dialog'>, 'onClick'> {
	/** Property that defines if the dialog is open */
	isModalOpen?: boolean
	/** Will toggle the action to close the dialog when the user clicks on the mask behind the dialog content */
	closeOnOutsideClick?: boolean
	/** Disables body scroll lock */
	disableScrollLock?: boolean
	/** Disable autofocus */
	disableAutofocus?: boolean
}

/**
 * Wrapper around the native `HTMLDialogElement` that polyfills its features for older browsers.
 * This should be `deprecated` after our minimum supported browser versions support the native
 * `dialog` element.
 */
const DialogBase = forwardRef<HTMLDialogElement | HTMLDivElement, DialogProps>(function DialogBase(
	{
		isModalOpen = false,
		children,
		className = '',
		closeOnOutsideClick = true,
		disableScrollLock = false,
		disableAutofocus = false,
		...attrs
	},
	ref,
) {
	const dialog = useRef({} as HTMLDialogElement)
	const [isDialogSupported, setIsDialogSupported] = useState<boolean>(true)
	const scrollRef = useRef<number>()
	const { currentDevice } = useDeviceDetect()

	useEffect(() => {
		const isSupported = typeof window.HTMLDialogElement === 'function'
		setIsDialogSupported(isSupported)
		if (!isSupported) {
			// Need to load dialog-polyfill synchronously for older browsers
			// eslint-disable-next-line
			window.dialogPolyfill = require('dialog-polyfill/dist/dialog-polyfill')
			window.dialogPolyfill?.registerDialog(dialog.current)
		}
	}, [])

	/**
	 * Handles the visibility case when the component is completely destroyed, or lazy loaded.
	 */
	useEffect(() => {
		if (disableScrollLock) return
		if (dialog.current.open && !isScrollingDisabled(document.body)) {
			preventScroll(scrollRef, currentDevice)
		}
	}, [dialog.current?.open, currentDevice, disableScrollLock])

	useEffect(() => {
		if (disableScrollLock) return () => undefined

		/**
		 * Prevents background scrolling while the `dialog` is opened. Also responsible for automatically
		 * setting up the _polyfilled_ `dialog` {@link handlePolyfilledOutsideClick} event handler.
		 */
		const observer = new MutationObserver((mutationList) => {
			// eslint-disable-next-line consistent-return
			mutationList.forEach((mutation) => {
				const dialog = mutation.target as HTMLDialogElement

				/**
				 * Only remove the overflow in the event that there are no open dialogs anywhere in the app.
				 */
				if (
					!dialog.hasAttribute('open') &&
					![...document.getElementsByTagName('dialog')].some((el) => el.hasAttribute('open'))
				) {
					return restoreScroll(scrollRef, currentDevice)
				}

				// for relative placement to top of page, we calculate and post the header height. Using offsetHeight to support desktop Safari, otherwise getBoundingClientRect().height is 0.
				const titleBoundingSize = (dialog.querySelector('[data-dialog-title]') as HTMLElement)?.offsetHeight
				const actionsBoundingSize = dialog.querySelector('[data-dialog-actions]')?.getBoundingClientRect()

				// set for dialog only
				if (dialog?.tagName === 'DIALOG') {
					dialog.style.setProperty('--dialog-header-height', `${titleBoundingSize ?? 0}px`)
					dialog.style.setProperty('--dialog-actions-height', `${actionsBoundingSize?.height ?? 0}px`)
				}

				if (!isScrollingDisabled(document.body)) {
					preventScroll(scrollRef, currentDevice)
				}
			})
		})

		observer.observe(dialog.current, { attributes: true, attributeFilter: ['open'], childList: true, subtree: true })

		return () => {
			observer.disconnect()

			// In case user navigates away via browser controls (back, forward) without closing caller panel (mobile nav, mobile search pane, etc)
			if (isScrollingDisabled(document.body)) {
				restoreScroll(scrollRef, currentDevice)
			}
		}
	}, [isDialogSupported, currentDevice, disableScrollLock])

	useEffect(() => {
		// If undefined, then this dialog's open/close state is probably managed by a ref
		// using dialogRef.showModal() so do a quick exit
		if (isModalOpen === undefined) return
		const hasOpenAttribute = dialog.current.hasAttribute('open')
		if (isModalOpen && !hasOpenAttribute) {
			if (isDialogSupported)
				setTimeout(() => {
					if (disableAutofocus && dialog.current) dialog.current.inert = true
					dialog.current?.showModal()
					if (disableAutofocus && dialog.current) dialog.current.inert = false
				}, 1)
			else dialog.current.setAttribute('open', 'true')
			return
		}

		if (!isModalOpen && hasOpenAttribute) {
			dialog.current.close()
		}
	}, [isDialogSupported, isModalOpen, disableAutofocus])

	useEffect(() => {
		const handleKeydown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				event.stopImmediatePropagation()
				dialog.current.close()
			}
		}
		document.body.addEventListener('keydown', handleKeydown)
		return () => document.body.removeEventListener('keydown', handleKeydown)
	}, [])

	return (
		// eslint-disable-next-line react/forbid-elements
		<dialog
			ref={exposeRefTo(dialog, ref)}
			aria-labelledby="dialog"
			role="dialog"
			data-testid="dialog-base"
			onMouseDown={(e) => (closeOnOutsideClick ? handleOutsideClick(e) : false)}
			className={clsx(className, styles.dialog, { [styles['dialog--polyfill']]: !isDialogSupported })}
			{...attrs}
		>
			{children}
		</dialog>
	)
})

/** Handler to close the `dialog` when `dialog::backdrop` is clicked, but not the `dialog`'s true content */
function handleOutsideClick(event: React.MouseEvent<HTMLDialogElement>): void {
	if (event.target === event.currentTarget) event.currentTarget.close()
}

/**
 * Prevents document body scrolling. (dialog background)
 * @param ref Local ref holding current scroll position
 * @returns {void}
 */
function preventScroll(ref: MutableRefObject<number | undefined>, currentDevice: CurrentDevice) {
	document.body.style.overflow = 'hidden'

	if (currentDevice === 'ios') {
		// The following properties are needed for iOS/Safari to respect overflow:hidden
		// eslint-disable-next-line no-param-reassign
		ref.current = window.scrollY
		document.body.style.setProperty('position', 'fixed')
		document.body.style.setProperty('width', '100%')
		document.body.style.setProperty('top', `${-1 * ref.current}px`)
	}
}

/**
 * Restores document body scrolling. (dialog background)
 * @param ref Local ref holding current scroll position
 * @returns {void}
 */
function restoreScroll(ref: MutableRefObject<number | undefined>, currentDevice: CurrentDevice) {
	document.body.style.removeProperty('overflow')

	if (currentDevice === 'ios') {
		// Removing properties needed for iOS/Safari workarounds
		document.body.style.removeProperty('position')
		document.body.style.removeProperty('width')
		document.body.style.removeProperty('top')
		window.scrollTo(0, parseInt(ref.current?.toString() || '0', 10))
	}
}

/**
 * Checks if document.body scrolling is disabled (overflow:hidden)
 * @param bodyElement The HTMLElement representing document.body
 * @returns True if scrolling is disabled (overflow is 'hidden'), false otherwise
 */
function isScrollingDisabled(bodyElement: HTMLElement): boolean {
	return bodyElement.style.overflow === 'hidden'
}

export default memo(DialogBase)
